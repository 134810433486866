<template>
  <div :class="['custom-table', isChooseList ? 'choose' : '']">
    <div v-if="data.length" class="custom-table__container">
      <div class="custom-table__header" :style="getRowStyle">
        <div v-if="isNumbered" class="custom-table__header-item custom-table__number-of-item">
          #
        </div>
        <div
          :class="[
            'custom-table__header-item',
            !isElementShowed(columnName) && 'custom-table__none-item',
          ]"
          v-for="columnName in Object.keys(prepareData[0])"
          :key="columnName.id"
        >
          <div class="custom-table__header-item--container" v-if="isElementShowed(columnName)">
            <div class="custom-table__header-item--text">{{ prepareColumnName(columnName) }}</div>
            <div
              v-if="columnName != 'groups' && columnName != 'created_by_user' && handleFilter"
              class="custom-table__filter"
              @click="handleFilter(columnName)"
            >
              <div
                :class="[
                  'custom-table__filter--asc custom-table__filter-item',
                  columnName == order && direction === true ? 'active' : '',
                ]"
              >
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66846 5.04297L0.33833 0.542968L8.99858 0.542969L4.66846 5.04297Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                :class="[
                  'custom-table__filter--desc custom-table__filter-item',
                  columnName == order && direction === false ? 'active' : '',
                ]"
              >
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66846 5.04297L0.33833 0.542968L8.99858 0.542969L4.66846 5.04297Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isActionBlockVisible"
          class="custom-table__action-block"
          :style="{ width: actionHeaderBlockWidth }"
        ></div>
      </div>
      <div class="custom-table__body">
        <div
          v-for="(row, index) in prepareData"
          :key="index"
          class="custom-table__row"
          :style="getRowStyle"
          @click="handleChoose ? handleChoose(row) : () => {}"
        >
          <div v-if="isNumbered" class="custom-table__row-item custom-table__number-of-item">
            {{ index + 1 }}
          </div>
          <div
            v-for="(item, key) in row"
            :key="key"
            @click="
              onActionColumn.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    onActionColumn.find((column) => column.columnName === key),
                    row,
                  )
                : booleanSwitcher.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    booleanSwitcher.find((column) => column.columnName === key),
                    row,
                  )
                : () => {}
            "
            :class="[
              'custom-table__row-item',
              !isElementShowed(key) && 'custom-table__none-item',
              onCustomStyleColumn.some((column) => column.columnName === key) &&
                onCustomStyleColumn.find((column) => column.columnName === key).className,
            ]"
          >
            <template v-if="isElementShowed(key)">
              <div v-if="isImage(key)">
                <img :src="item" />
              </div>
              <template v-else-if="isSwitcher(key)">
                <div :class="[item ? 'switcher' : 'switcher switcher--disabled']">
                  {{ item ? "yes" : "no" }}
                </div>
              </template>
              <div
                v-else-if="key === 'status_id' && name === 'templates' && row.statusFull != null"
                :class="[row.statusFull.id === 4 ? 'link' : '']"
              >
                {{ item }}
              </div>
              <div v-else-if="key === 'id' && name === 'templates'">
                <router-link class="link" :to="getUrlTemplate(item)">{{ item }}</router-link>
              </div>
              <div v-else-if="key === 'status' && name != 'templates'">
                {{ item ? "active" : "not active" }}
              </div>
              <div v-else>{{ item }}</div>
            </template>
          </div>
          <div v-if="isActionBlockVisible" class="custom-table__action-block" ref="actionBlock">
            <div v-if="handleCopy" class="custom-table__icon">
              <svg
                @click="handleCopy(row)"
                width="25"
                height="15"
                viewBox="0 0 25 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.625 0.0585938C8.03998 0.0585938 3.88297 2.56264 0.813173 6.64094C0.562276 6.97547 0.562276 7.43791 0.813173 7.77244C3.88297 11.8557 8.03998 14.3646 12.625 14.3646C17.21 14.3646 21.367 11.8606 24.4368 7.78228C24.6877 7.44775 24.6877 6.98531 24.4368 6.65078C21.367 2.56756 17.21 0.0585938 12.625 0.0585938ZM12.9546 12.2492C9.90941 12.4411 7.40044 9.93212 7.59231 6.88692C7.74973 4.37795 9.78642 2.33634 12.3003 2.17892C15.3455 1.98706 17.8545 4.49602 17.6626 7.54122C17.5003 10.0502 15.4636 12.0869 12.9546 12.2492Z"
                  fill="#2F2F34"
                />
                <path
                  d="M12.802 9.922C11.1638 10.0253 9.81093 8.67244 9.91424 7.03423C9.99787 5.68136 11.0949 4.5843 12.4478 4.50067C14.086 4.39736 15.4389 5.75023 15.3356 7.38844C15.2519 8.74131 14.1549 9.83837 12.802 9.922Z"
                  fill="#2F2F34"
                />
              </svg>
            </div>
            <div v-if="handleEdit" class="custom-table__icon">
              <svg
                @click="handleEdit(row)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 19.9999L5.39452 19.4417L0.558452 14.6055L0 19.9999Z" fill="#2D2C2C" />
                <path
                  d="M12.0017 3.16246L16.8378 7.99853L6.32444 18.5118L1.48828 13.6758L12.0017 3.16246ZM19.336 5.50018C20.2213 4.61491 20.2217 3.17955 19.336 2.29401L17.706 0.66402C16.8208 -0.221431 15.3852 -0.221249 14.4999 0.66402L12.923 2.24101L17.7592 7.07699L19.336 5.50018Z"
                  fill="#2D2C2C"
                />
              </svg>
            </div>
            <div v-if="handleDelete" class="custom-table__icon">
              <svg
                @click="handleDelete(row)"
                width="14"
                height="20"
                viewBox="0 0 14 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7727 2.26059H10.631V2.14844C10.631 0.963745 9.66724 0 8.48254 0H5.50891C4.32422 0 3.36047 0.963745 3.36047 2.14844V2.26059H1.21875C0.571625 2.26059 0.046875 2.78519 0.046875 3.43246V4.32571C0.046875 4.54147 0.221741 4.71634 0.4375 4.71634H13.554C13.7697 4.71634 13.9446 4.54147 13.9446 4.32571V3.43246C13.9446 2.78519 13.42 2.26059 12.7727 2.26059ZM4.53235 2.14844C4.53235 1.60995 4.97043 1.17188 5.50891 1.17188H8.48254C9.02103 1.17188 9.45911 1.60995 9.45911 2.14844V2.26059H4.53235V2.14844Z"
                  fill="#2D2C2C"
                />
                <path
                  d="M0.552734 5.88672L1.49954 19.4533C1.52106 19.7603 1.77634 19.9985 2.08411 19.9985H11.9076C12.2153 19.9985 12.4706 19.7603 12.492 19.4533L13.4386 5.88672H0.552734ZM4.67688 18.0057C4.66849 18.0062 4.65994 18.0063 4.65155 18.0063C4.33951 18.0063 4.07996 17.76 4.06668 17.4453L3.67606 8.26556C3.66217 7.94223 3.91318 7.66895 4.23651 7.65521C4.56 7.64194 4.83313 7.89249 4.84686 8.21582L5.23749 17.3955C5.25122 17.7188 5.00021 17.992 4.67688 18.0057ZM7.5817 17.4204C7.5817 17.744 7.3194 18.0063 6.99576 18.0063C6.67212 18.0063 6.40982 17.744 6.40982 17.4204V8.24069C6.40982 7.91705 6.67212 7.65475 6.99576 7.65475C7.3194 7.65475 7.5817 7.91705 7.5817 8.24069V17.4204ZM9.92499 17.4453C9.91156 17.7602 9.65201 18.0063 9.33997 18.0063C9.33157 18.0063 9.32303 18.0062 9.31464 18.0059C8.9913 17.9921 8.7403 17.7188 8.75403 17.3955L9.14465 8.21582C9.15854 7.89249 9.43213 7.64163 9.755 7.65537C10.0783 7.6691 10.3293 7.94223 10.3156 8.26556L9.92499 17.4453Z"
                  fill="#2D2C2C"
                />
              </svg>
            </div>
            <div v-if="handleLogs" class="custom-table__icon">
              <svg
                @click="handleLogs(row)"
                xmlns="http://www.w3.org/2000/svg"
                fill="#2D2C2C"
                width="20px"
                height="20px"
                viewBox="0 0 32 32"
                version="1.1"
              >
                <title>logs</title>
                <path
                  d="M0 24q0 0.832 0.576 1.44t1.44 0.576h1.984q0 2.496 1.76 4.224t4.256 1.76h6.688q-2.144-1.504-3.456-4h-3.232q-0.832 0-1.44-0.576t-0.576-1.408v-20q0-0.832 0.576-1.408t1.44-0.608h16q0.8 0 1.408 0.608t0.576 1.408v7.232q2.496 1.312 4 3.456v-10.688q0-2.496-1.76-4.256t-4.224-1.76h-16q-2.496 0-4.256 1.76t-1.76 4.256h-1.984q-0.832 0-1.44 0.576t-0.576 1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408zM10.016 24h2.080q0-0.064-0.032-0.416t-0.064-0.576 0.064-0.544 0.032-0.448h-2.080v1.984zM10.016 20h2.464q0.288-1.088 0.768-1.984h-3.232v1.984zM10.016 16h4.576q0.992-1.216 2.112-1.984h-6.688v1.984zM10.016 12h16v-1.984h-16v1.984zM10.016 8h16v-1.984h-16v1.984zM14.016 23.008q0 1.824 0.704 3.488t1.92 2.88 2.88 1.92 3.488 0.704 3.488-0.704 2.88-1.92 1.92-2.88 0.704-3.488-0.704-3.488-1.92-2.88-2.88-1.92-3.488-0.704-3.488 0.704-2.88 1.92-1.92 2.88-0.704 3.488zM18.016 23.008q0-2.080 1.44-3.52t3.552-1.472 3.52 1.472 1.472 3.52q0 2.080-1.472 3.52t-3.52 1.472-3.552-1.472-1.44-3.52zM22.016 23.008q0 0.416 0.288 0.704t0.704 0.288h1.984q0.416 0 0.704-0.288t0.32-0.704-0.32-0.704-0.704-0.288h-0.992v-0.992q0-0.416-0.288-0.704t-0.704-0.32-0.704 0.32-0.288 0.704v1.984z"
                />
              </svg>
            </div>
            <div v-if="handleAccept" class="custom-table__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                @click="handleAccept(row)"
                width="20"
                height="20"
                viewBox="0 0 256 256"
                xml:space="preserve"
                title="Accept"
              >
                <defs></defs>
                <g
                  style="
                    stroke: none;
                    stroke-width: 0;
                    stroke-dasharray: none;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    fill: none;
                    fill-rule: nonzero;
                    opacity: 1;
                  "
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 63.599 26.542 c 0.372 -1.874 -0.019 -3.717 -1.1 -5.192 c -1.08 -1.475 -2.72 -2.404 -4.618 -2.616 c -1.867 -0.211 -3.754 0.306 -5.315 1.449 L 20.782 43.458 l 0.157 -8.239 c 0.035 -1.841 -0.665 -3.545 -1.971 -4.798 c -1.353 -1.298 -3.17 -1.943 -5.123 -1.83 c -3.9 0.236 -7.132 3.527 -7.205 7.336 L 6.074 65.765 c -0.121 5.33 1.429 10.352 4.483 14.521 c 4.676 6.385 12.09 9.714 19.837 9.713 c 5.729 0 11.642 -1.82 16.764 -5.571 l 13.302 -9.742 c 3.258 -2.387 4.104 -6.794 1.886 -9.823 c -1.08 -1.475 -2.72 -2.404 -4.618 -2.615 c -0.012 -0.001 -0.025 -0.001 -0.037 -0.002 l 0.35 -0.256 c 1.56 -1.143 2.621 -2.786 2.985 -4.629 c 0.372 -1.873 -0.019 -3.718 -1.1 -5.192 c -1.08 -1.475 -2.72 -2.404 -4.618 -2.616 c -0.342 -0.038 -0.684 -0.044 -1.025 -0.034 c 0.435 -0.727 0.751 -1.52 0.917 -2.358 c 0.371 -1.874 -0.019 -3.718 -1.1 -5.193 c -1.08 -1.475 -2.72 -2.404 -4.618 -2.616 c -0.012 -0.001 -0.024 -0.001 -0.036 -0.002 l 11.167 -8.178 C 62.174 30.029 63.234 28.384 63.599 26.542 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 66.656 22.3 c -0.011 0 -0.023 0 -0.035 0 c -0.616 -0.011 -1.19 -0.313 -1.549 -0.814 l -3.595 -5.023 c -0.627 -0.875 -0.425 -2.092 0.45 -2.718 c 0.876 -0.627 2.093 -0.423 2.718 0.45 l 2.069 2.891 l 5.757 -7.465 c 0.658 -0.853 1.88 -1.011 2.733 -0.353 c 0.851 0.657 1.01 1.881 0.353 2.733 l -7.358 9.542 C 67.83 22.02 67.259 22.3 66.656 22.3 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 67.631 0 c -8.989 0 -16.302 7.313 -16.302 16.302 c 0 0.2 0.015 0.399 0.022 0.599 c 1.217 -0.743 2.545 -1.24 3.919 -1.471 C 55.72 8.996 61.085 3.897 67.631 3.897 c 6.84 0 12.406 5.565 12.406 12.406 c 0 6.84 -5.565 12.405 -12.406 12.405 c -0.368 0 -0.729 -0.03 -1.091 -0.061 c -0.463 1.331 -1.18 2.558 -2.118 3.623 c 1.048 0.209 2.116 0.335 3.208 0.335 c 8.989 0 16.302 -7.313 16.302 -16.302 C 83.933 7.313 76.62 0 67.631 0 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                </g>
              </svg>
            </div>
            <div v-if="handleDecline" class="custom-table__icon">
              <svg
                @click="handleDecline(row)"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 256 256"
                xml:space="preserve"
                title="Decline"
              >
                <defs></defs>
                <g
                  style="
                    stroke: none;
                    stroke-width: 0;
                    stroke-dasharray: none;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    fill: none;
                    fill-rule: nonzero;
                    opacity: 1;
                  "
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 24.959 68.04 c -0.768 0 -1.536 -0.293 -2.121 -0.879 c -1.172 -1.171 -1.172 -3.071 0 -4.242 l 40.081 -40.081 c 1.172 -1.172 3.07 -1.172 4.242 0 c 1.172 1.171 1.172 3.071 0 4.242 L 27.081 67.161 C 26.495 67.747 25.727 68.04 24.959 68.04 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 65.04 68.04 c -0.768 0 -1.535 -0.293 -2.121 -0.879 L 22.838 27.081 c -1.172 -1.171 -1.172 -3.071 0 -4.242 c 1.171 -1.172 3.071 -1.172 4.242 0 l 40.081 40.081 c 1.172 1.171 1.172 3.071 0 4.242 C 66.575 67.747 65.808 68.04 65.04 68.04 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                  <path
                    d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 6 C 23.495 6 6 23.495 6 45 s 17.495 39 39 39 s 39 -17.495 39 -39 S 66.505 6 45 6 z"
                    style="
                      stroke: none;
                      stroke-width: 1;
                      stroke-dasharray: none;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      fill: rgb(0, 0, 0);
                      fill-rule: nonzero;
                      opacity: 1;
                    "
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                  />
                </g>
              </svg>
            </div>
            <!--<div v-if="handleCancel" class="custom-table__icon">-->
            <!--<Cancel @click="handleCancel(row)" />-->
            <!--</div>-->
            <div v-if="handleButton" class="custom-table__button">
              <MainButton @click.native="handleButton(row)">Choose</MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>
<script>
import MainButton from "../atoms/buttons/MainButton.vue";
import ROUTE from "~/constants/routes";
export default {
  name: "CustomTable",
  props: {
    data: {
      type: [Array],
      default() {
        return [];
      },
    },
    handleFilter: {
      type: [Function, Boolean],
      default: false,
    },
    handleEdit: {
      type: [Function, Boolean],
      default: null,
    },
    handleDelete: {
      type: [Function, Boolean],
      default: null,
    },
    handleLogs: {
      type: [Function, Boolean],
      default: null,
    },
    handleCopy: {
      type: [Function, Boolean],
      default: null,
    },
    handleCancel: {
      type: [Function, Boolean],
      default: null,
    },
    handleAccept: {
      type: [Function, Boolean],
      default: null,
    },
    handleDecline: {
      type: [Function, Boolean],
      default: null,
    },
    handleButton: {
      type: [Function, Boolean],
      default: null,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: null,
    },
    onActionColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    onCustomStyleColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    customOrder: {
      type: [Array, Boolean],
      default: null,
    },
    isNumbered: {
      type: Boolean,
      default: false,
    },
    isActionBlockVisible: {
      type: Boolean,
      default: false,
    },
    isChooseList: {
      type: Boolean,
      default: false,
    },
    handleChoose: {
      type: [Function, Boolean],
      default: false,
    },
    deletedKeys: {
      type: [Array],
      default() {
        return [];
      },
    },
    booleanSwitcher: {
      type: [Array],
      default() {
        return [];
      },
    },
    images: {
      type: [Array],
      default() {
        return [];
      },
    },
    renameColumns: {
      type: [Array],
      default() {
        return [];
      },
    },
    direction: {
      type: Boolean,
      default: null,
    },
    order: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    queryParams: {
      type: String,
      default: "",
    },
  },
  components: {
    MainButton,
  },
  data() {
    return {
      actionHeaderBlockWidth: "auto",
    };
  },
  computed: {
    prepareData() {
      const { data } = this;
      const preparedData = data.map((item) => {
        if (this.customOrder.length) {
          const preparedItem = {};
          this.customOrder.map((key) => {
            preparedItem[key] = item[key];
            return true;
          });
          return preparedItem;
        }
        return item;
      });
      return preparedData;
    },
    getRowStyle() {
      const getAvalibleLength = Object.keys(this.prepareData[0]).length;
      if (!getAvalibleLength) return false;
      const totalLength = getAvalibleLength - this.deletedKeys.length;
      const gridArray = Array(totalLength).fill("1fr").join(" ");
      return {
        display: "grid",
        "grid-template-columns": `${gridArray} ${this.isNumbered ? "1fr" : ""} ${
          this.onActionColumn ? "1fr" : ""
        }`,
      };
    },
  },
  methods: {
    prepareColumnName(name) {
      if (this.renameColumns.length) {
        let newName = name;
        this.renameColumns.forEach((item) => {
          if (item.from === name) {
            newName = item.to;
          }
        });
        return newName;
      }
      return name;
    },
    getUrlTemplate(id) {
      return ROUTE.TEMPLATE_EDIT.replace(":id", id);
    },
    isElementShowed(element) {
      if (this.deletedKeys.length && typeof element === "string") {
        return !this.deletedKeys.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isImage(element) {
      if (this.images.length && typeof element === "string") {
        return this.images.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isSwitcher(element) {
      if (this.booleanSwitcher.length && typeof element === "string") {
        return this.booleanSwitcher.some(
          (el) => el.columnName.toLowerCase() === element.toLowerCase(),
        );
      }
      return false;
    },
    handleActionOnClick(item, row) {
      item.action(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  min-width: 100%;
  background: #fff;
  &__header {
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    background: $coffee;
  }
  &__container {
    margin-top: 20px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
  }
  &__header-item {
    display: flex;
    align-items: center;
    padding-right: 5px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: left;
  }
  &__header-item--text {
    display: flex;
    align-items: center;
    width: max-content;
    word-wrap: break-word;
  }
  &__header-item--container {
    display: flex;
    justify-content: center;
  }
  &__filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 5px;
    &-item {
      height: 6px;
      width: 10px;
    }
    &-item.active {
      svg {
        path {
          fill: $darkCoffee;
        }
      }
    }
  }
  &__filter--asc {
    position: relative;
    transform: rotate(180deg);
    top: 5px;
  }
  &__filter--desc {
    position: relative;
    top: -5px;
  }
  &__button {
    /deep/.button-main {
      width: 136px;
      height: 36px;
    }
  }
  &__row {
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    align-items: right;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #dbdbdb;
      bottom: 0;
    }
  }
  &__row.choose {
    cursor: pointer;
    &:hover {
      background: $darkCoffee;
      .custom-table__row-item {
        color: #fff;
      }
    }
  }
  &__row-item {
    width: 100%;
    font-size: 14px;
    text-align: left;
    display: flex;
    align-items: center;
    color: black;
    padding-right: 5px;
    overflow: hidden;
    div {
      // overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .link {
      color: $coffee;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__none-item {
    display: none;
  }
  &__number-of-item {
    width: 15%;
  }
  &__action-block {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
  }
  .switcher {
    text-align: center;
    width: 100%;
    background: #ff4d4d;
    border-radius: 25px;
    padding: 4px 0;
    cursor: pointer;
    margin: 5px;
    &--disabled {
      background: #c4c4c4;
    }
  }
  &__icon {
    display: flex;
    svg {
      margin: auto 0;
      cursor: pointer;
      path {
        fill: $coffee;
      }
      rect {
        fill: $coffee;
      }
    }
  }
  &__icon:hover {
    path {
      fill: $coffee;
    }
    rect {
      fill: $coffee;
    }
  }
  &__icon:active {
    path {
      fill: $coffee;
    }
    rect {
      fill: $coffee;
    }
  }
}
.custom-table.choose {
  .custom-table__container {
    margin-top: 0;
    margin-bottom: 2px;
  }
  .custom-table__row {
    cursor: pointer;
    &:hover {
      background: $coffee;
      .custom-table__row-item {
        color: #fff;
      }
    }
  }
}
.red {
  color: #eb5959;
}
.green {
  color: #5fbf68;
}
.column-preview {
  div {
    padding-right: 10px;
  }
  img {
    width: 100%;
  }
}
/deep/.custom-table__icon {
  margin-right: 15px;
}
</style>
