<template>
  <div class="pagination">
    <div class="pagination__total">{{ totalItem }} item</div>

    <div class="pagination__container">
      <router-link
        class="pagination__prev-page"
        v-if="page !== 1"
        :to="getUrl.replace(':page', page - 1) + queryParams"
      >
        <div></div>
      </router-link>
      <router-link
        v-for="currentPage in pages"
        :key="currentPage"
        :class="[
          'pagination__item',
          currentPage === page && 'active',
          currentPage === null && 'null',
        ]"
        :to="getUrl.replace(':page', currentPage) + queryParams"
      >
        {{ currentPage }}
      </router-link>
      <router-link
        class="pagination__next-page"
        v-if="page !== maxPage"
        :to="getUrl.replace(':page', page + 1) + queryParams"
      >
        <div></div>
      </router-link>
    </div>
    <div class="pagination__count">
      {{ page }} of
      <router-link class="pagination__last" :to="getUrl.replace(':page', maxPage) + queryParams">{{
        maxPage
      }}</router-link>
    </div>
  </div>
</template>

<script>
import ROUTE from "~/constants/routes";

export default {
  name: "Pagination",
  props: {
    maxPage: {
      type: [Number, String],
      default: 0,
    },
    page: {
      type: [Number, String],
      default: 0,
    },
    totalItem: {
      type: [Number, String],
      default: 0,
    },
    urlList: {
      type: String,
      default: "",
    },
    feedId: {
      type: String,
      default: null,
    },
    queryParams: {
      type: String,
      default: "",
    },
  },
  computed: {
    pages() {
      if (this.maxPage == 1) {
        return [1];
      } else {
        const pagesArray = [
          this.page != 2 && this.page != 1 ? this.page - 2 : null,
          this.page != 1 ? this.page - 1 : null,
          this.page,
          this.page != this.maxPage ? this.page + 1 : null,
          this.page != this.maxPage - 1 && this.page != this.maxPage ? this.page + 2 : null,
        ];
        return pagesArray;
      }
    },
    getUrl() {
      if (this.feedId != null) {
        return ROUTE[this.urlList].replace(":feedId", this.feedId);
      } else {
        return ROUTE[this.urlList];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;

  &__total {
    padding-right: 20px;
  }

  &__count {
    padding-left: 20px;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__item {
    font-size: 14px;
    margin: 0 10px;
    cursor: pointer;
    color: $mainColor;
  }

  &__item.null {
    margin: 0;
  }

  &__last {
    color: $coffee;
  }

  &__item.active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 1px;
    background: $coffee;
    font-size: 14px;
    color: #fff;
    pointer-events: none;
  }

  &__prev-page {
    display: inline-block;
    background: $coffee;
    opacity: 0.85;
    border-radius: 1px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      background: $coffee;
      opacity: 0.7;
    }
    div {
      background-image: url("../../assets/images/icons/slick-prev.png");
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__next-page {
    display: inline-block;
    background: $coffee;
    opacity: 0.85;
    border-radius: 1px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      background: $coffee;
      opacity: 0.7;
    }
    div {
      background-image: url("../../assets/images/icons/slick-next.png");
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>
